.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

textarea {
  width: calc(100% - 40px);
  height: 200px;
  margin: 20px;
}

.sentence {
  width: calc(100% - 40px);
  background-color: aquamarine;
  margin: 5px 20px;
}

.highlighted-sentence {
  background-color: chartreuse;
}

#rate-control {
  width: calc(100% - 40px);
  margin: 20px;
  display: flex;
  align-items: center;
  gap: 1rem;
  /* margin: 1rem 0; */
}